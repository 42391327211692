<template>
  <div class="styleguide">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Homepage</h1>
          <h2>Lorem ipsum dolor sit amet.</h2>
          <h3>Consectetur adipisicing elit. Deserunt voluptas asperiores ducimus, esse ipsa quod sunt dolores quidem accusamus!</h3>
          <h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus voluptatem, ratione modi numquam soluta distinctio animi maxime iusto quibusdam aspernatur deleniti odio beatae. Culpa qui amet eligendi voluptates delectus corporis!</h4>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit veniam, doloribus magni ipsa recusandae eum aspernatur ex ut, vitae atque officia! Itaque voluptas libero modi totam, fugiat dolorum suscipit temporibus?</p>
          <p class="--lead">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit veniam, doloribus magni ipsa recusandae eum aspernatur ex ut, vitae atque officia! Itaque voluptas libero modi totam, fugiat dolorum suscipit temporibus?
          </p>
          <ul>
            <li>re</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga non cupiditate nobis tenetur eum omnis doloremque harum excepturi perferendis laborum ratione beatae error quo voluptatibus eaque rem aliquam, maxime voluptate.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga non cupiditate nobis tenetur eum omnis doloremque harum excepturi perferendis laborum ratione beatae error quo voluptatibus eaque rem aliquam, maxime voluptate.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga non cupiditate nobis tenetur eum omnis doloremque harum excepturi perferendis laborum ratione beatae error quo voluptatibus eaque rem aliquam, maxime voluptate.</li>
            <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga non cupiditate nobis tenetur eum omnis doloremque harum excepturi perferendis laborum ratione beatae error quo voluptatibus eaque rem aliquam, maxime voluptate.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'styleguide',
  props: '',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  }
}

</script>
